import React, { useState } from 'react';
import './NavBar.css';

// Funktion zum manuellen Scrollen, um Safari-Probleme zu umgehen
export const scrollElementIntoView = (element, behavior = 'auto') => {
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
  
  // Höhe des Headers (falls vorhanden) wird berücksichtigt
  const headerHeight = document.querySelector('.header')?.clientHeight || 0;
  
  const finalOffset = element.getBoundingClientRect().top + scrollTop - headerHeight;

  window.scrollTo({
    top: finalOffset,
    behavior: behavior
  });
};

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.classList.add('nav-open');
    } else {
      document.body.classList.remove('nav-open');
    }
  };

  // Funktion, um die Seite neu zu laden (F5-Verhalten)
  const refreshPage = () => {
    window.location.reload(); // Seite neu laden
  };

  const handleNavClick = (event, sectionId) => {
    event.preventDefault(); // Verhindere das Standardverhalten des Links
    const section = document.getElementById(sectionId);
    if (section) {
      scrollElementIntoView(section, 'smooth'); // Nutze die neue Scroll-Funktion
    }
    toggleMenu(); // Menü schließen, wenn ein Link geklickt wird
  };

  return (
    <>
      {/* Header with logo and hamburger */}
      <div className="header">
        <div className="header-content" onClick={refreshPage} style={{ cursor: 'pointer' }}>
          <div className="header-logo">
            <span className="studio-name">
              <img src="/img/logo.png" alt="Kosmetikstudio Logo" className="logo-image" />
            </span>
          </div>
          <div className="header-text">
            <span className="studio-title">Kosmetikstudio</span>
            <span className="owner-name">Christine Wyss-Fischer</span>
          </div>
        </div>
        <div className="header-menu" onClick={toggleMenu}>
          {isOpen ? <span className="nav-close">&times;</span> : <span>&#9776;</span>}
        </div>
      </div>

      {/* Full-screen navigation menu */}
      <div className={isOpen ? 'nav open' : 'nav'}>
        <div className="nav-close" onClick={toggleMenu}>
          &times;
        </div>
        <div className="nav-item"><a href="#welcome" onClick={(e) => handleNavClick(e, 'welcome')}>Startseite</a></div>
        <div className="nav-item"><a href="#studio" onClick={(e) => handleNavClick(e, 'studio')}>Studio</a></div>
        <div className="nav-item"><a href="#about" onClick={(e) => handleNavClick(e, 'about')}>About</a></div>
        <div className="nav-item"><a href="#preise" onClick={(e) => handleNavClick(e, 'preise')}>Preise</a></div>
        <div className="nav-item"><a href="#kontakt" onClick={(e) => handleNavClick(e, 'kontakt')}>Kontakt</a></div>
      </div>
    </>
  );
}

export default NavBar;
