import React from 'react';
import './About.css';

function About() {
  return (
    <div className="section about-section">
      <h1>Über Mich</h1>
      <div className="about-container">
        <img src="/img/placeholder-portrait.jpg" alt="Kosmetikerin Portrait" className="about-portrait" />
        <div className="about-text">
          <h2>Willkommen!</h2>
          <p>
            Ich bin [Name], Ihre Kosmetikerin im Herzen von Oberburg. Mit über 10 Jahren Erfahrung 
            biete ich jedem meiner Kunden ein besonderes Pflegeerlebnis.
          </p>
          <p>
            Meine Leidenschaft für Hautpflege begann früh, und ich arbeite ständig daran, meine Fähigkeiten durch Schulungen zu erweitern. 
            Ob Gesichtsbehandlung, Maniküre oder Beratung – mein Ziel ist es, dass Sie sich wohlfühlen und Ihre natürliche Schönheit erstrahlt.
          </p>
          <p>
            In meinem Studio verwende ich hochwertige Produkte und moderne Techniken, um die besten Ergebnisse zu garantieren. 
            Jeder Besuch bei mir soll Entspannung und Erneuerung bringen.
          </p>
          <p>
            Ich freue mich, Sie bald bei mir zu begrüßen!
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
