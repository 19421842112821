import React from 'react';

function Kontakt() {
  return (
    <div className="section">
      <h1>Termin</h1>

      <img src="/img/qr.png" alt="Contact" />
      <p>Scannen Sie den angezeigten QR-Code, um mich direkt über WhatsApp hinzuzufügen und einen Termin zu buchen.</p>
      <p>Oder rufen Sie an </p>
      <h2>076 503 29 80</h2>
      <p>und hinterlassen Sie Name, Angebot und Wunschtermin. Ich melde mich umgehend!</p>
    </div>
  );
}

export default Kontakt;
