import React, { useState } from 'react';
import './Preise.css';

function Preise() {
  const [activeCategory, setActiveCategory] = useState(null);
  const [isImageFullScreen, setImageFullScreen] = useState(false);

  const toggleCategory = (category) => {
    if (activeCategory === category) {
      setActiveCategory(null); // Collapse if already active
    } else {
      setActiveCategory(category); // Expand the clicked category
    }
  };

  const toggleFullScreenImage = () => {
    setImageFullScreen(!isImageFullScreen);
  };

  return (
    <div className="section">
      <h1>Preise</h1>

      <div className="accordion">
        {/* Gesicht */}
        <div className="category">
          <div className="price-category-heading" onClick={() => toggleCategory('gesicht')}>
            Gesicht
          </div>
          <ul className={activeCategory === 'gesicht' ? 'active' : 'collapsed'}>
            <li><span>Wimpern färben</span><span className="price">25.-</span></li>
            <li><span>Brauen zupfen und färben</span><span className="price">30.-</span></li>
            <li><span>Brauen zupfen</span><span className="price">15.-</span></li>
            <li><span>Wimpern/Brauen färben (zupfen)</span><span className="price">55.-</span></li>
            <li><span>Gesichtsbehandlung</span><span className="price">130.-</span></li>
            <li><span>Akne Behandlung</span><span className="price">130.-</span></li>
          </ul>
        </div>

        {/* Gesicht Wachsen */}
        <div className="category">
          <div className="price-category-heading" onClick={() => toggleCategory('gesichtWachsen')}>
            Gesicht Wachsen
          </div>
          <ul className={activeCategory === 'gesichtWachsen' ? 'active' : 'collapsed'}>
            <li><span>Oberlippe</span><span className="price">10.-</span></li>
            <li><span>Ganzes Gesicht</span><span className="price">25.-</span></li>
          </ul>
        </div>

        {/* Körper Wachsen */}
        <div className="category">
          <div className="price-category-heading" onClick={() => toggleCategory('körperWachsen')}>
            Körper Wachsen
          </div>
          <ul className={activeCategory === 'körperWachsen' ? 'active' : 'collapsed'}>
            <li><span>Achsel</span><span className="price">20.-</span></li>
            <li><span>Arme</span><span className="price">40.-</span></li>
            <li><span>Bauch/Brust/Rücken</span><span className="price">Nach Aufwand</span></li>
            <li><span>Bikini Zone</span><span className="price">25.-</span></li>
            <li><span>Ganze Beine</span><span className="price">80.-</span></li>
            <li><span>Halbe Beine</span><span className="price">40.-</span></li>
          </ul>
        </div>

        {/* Hand und Fusspflege */}
        <div className="category">
          <div className="price-category-heading" onClick={() => toggleCategory('handFusspflege')}>
            Hand und Fusspflege
          </div>
          <ul className={activeCategory === 'handFusspflege' ? 'active' : 'collapsed'}>
            <li><span>Maniküre</span><span className="price">40.-</span></li>
            <li><span>Mit Lack</span><span className="price">45.-</span></li>
            <li><span>Kosmetische Fusspflege</span><span className="price">60.-</span></li>
            <li><span>Mit Lack</span><span className="price">65.-</span></li>
          </ul>
        </div>

        {/* Massage */}
        <div className="category">
          <div className="price-category-heading" onClick={() => toggleCategory('massage')}>
            Massage
          </div>
          <ul className={activeCategory === 'massage' ? 'active' : 'collapsed'}>
            <li><span>Rückenmassage</span><span className="price">80.-</span></li>
          </ul>
        </div>
      </div>

      {/* Preview image section */}
      <div className="image-preview">
        <img
          src="/img/price.png"
          alt="Preisliste Vorschau"
          className="preview-image"
          onClick={toggleFullScreenImage}
        />
      </div>

      {/* Fullscreen image */}
      {isImageFullScreen && (
        <div className="fullscreen-image-container">
          <span className="close-button" onClick={toggleFullScreenImage}>
            &times;
          </span>
          <img src="/img/price.png" alt="Preisliste Vollbild" className="fullscreen-image" />
        </div>
      )}
    </div>
  );
}

export default Preise;